import * as Yup from 'yup';

export const newTransactionSchema = Yup.object().shape({
  customerID: Yup.string()
    .required('Cédula del Cliente requerida')
    .matches(/^[0-9]+$/, 'La cédula debe contener solo números')
    .length(7, 'La cédula debe tener exactamente 7 dígitos'),
  nameAndLastname: Yup.string().required('Nombre y Apellido requerido'),
  transactionNumber: Yup.string().required('Número de Factura requerido'),
  currency: Yup.string().required('Seleccione un tipo de Moneda'),
  dues: Yup.string().required('Seleccione número de cuotas'),
  amount: Yup.number()
    .positive('The amount must be positive')
    .test('is-decimal', 'Invalid decimal', (value) => {
      if (value) {
        const parts = value.toString().split('.');
        if (parts.length === 2 && parts[1].length > 5) {
          return false;
        }
      }
      return true;
    })
    .required('Importe requerido')
});

export const newTransactionInitialValues = {
  customerID: '', //document
  nameAndLastname: '',
  transactionNumber: '',
  currency: '',
  dues: '',
  amount: 0.0
};
