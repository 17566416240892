import axios from 'axios';

import { BuyerEntity } from 'modules/Buyer/types/buyer.type';

import { IApiResponse } from 'types/responses/apiResponse.interface';
import { BuyerLoginResponse } from 'types/responses/buyerLogin.type';

import { setLocalStorage } from 'utils/localstorage';

import { api, baseURL } from './instance';

export const BuyerService = {
  login: async (data: { email: string; password: string }) => {
    const res = await axios.post<IApiResponse<BuyerLoginResponse>>(
      `${baseURL}/buyer/login`,
      data
    );

    const token = res.data.data.token;
    setLocalStorage('token', token);

    return token;
  },
  me: async () => {
    const res = await api.get<IApiResponse<BuyerEntity>>('/buyer/info');
    return res.data.data;
  }
};
