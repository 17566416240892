import { Pagination } from 'types/pagination.type';

import { Transaction } from './transaction.type';

export interface ITransactionState {
  errorMessage?: string;
  loading: boolean;
  transactions: Transaction[];
  transactionsForClose: Transaction[];
  pendingTransactions: Transaction[];
  pagination: Pagination;
  transactionModal: TransactionModal;
}

export enum TransactionModalStatus {
  WAITING = 'waiting',
  APPROVED = 'approved',
  'REJECTED-BY-CD' = 'rejected-by-cd',
  'REJECTED-BY-BUYER' = 'rejected-by-buyer',
  FAILED = 'failed',
  SENT = 'sent',
  ANULLING = 'anulling',
  ANULLED = 'anulled',
  NOT_ANULLED = 'not-anulled'
}

export type TransactionModal = {
  isOpen: boolean;
  status?: TransactionModalStatus;
  transaction?: Transaction;
  counter?: number;
};
