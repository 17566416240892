import { formatDate } from 'helpers/DateTransformer';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Button, Grid } from '@mui/material';

import Label from 'design-system/Label';

import {
  getTransactions,
  rejectRequestAction
} from 'redux/transaction/transaction.action';
import { setTransactionModalIsOpen } from 'redux/transaction/transaction.slice';
import {
  Transaction,
  TransactionStatus
} from 'redux/transaction/types/transaction.type';
import { userSelector } from 'redux/user/user.selector';

import Roles from 'types/roles.enum';

import { shortTransactionNumber } from 'utils/shortTransactionNumber';

interface MobileTransactionProp {
  transaction: Transaction;
}
export const TransactionMobileElement: React.FC<MobileTransactionProp> = ({
  transaction
}) => {
  const [clickedTransactionId, setClickedTransactionId] = useState<
    string | null
  >(null);
  const gridItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
    fontWeight: 600
  };
  const { role } = useAppSelector(userSelector);
  const isCommerce = [Roles.ADMIN_COMMERCE, Roles.COMMERCE].includes(role);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleCancelClick = (transactionId: string) => {
    handleCancel(transactionId);
    setClickedTransactionId(transactionId);
  };

  const handleCancel = async (transactionId: string) => {
    const resp = await dispatch(rejectRequestAction(transactionId));

    if (resp.meta.requestStatus === 'fulfilled') {
      dispatch(getTransactions(queryString.parse(location.search)));
      dispatch(setTransactionModalIsOpen(false));
      setClickedTransactionId(null);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '0.75rem',
          fontWeight: 600,
          marginTop: '0.5rem'
        }}>
        <Label fontWeight={500}>Fecha</Label>
        <Label>{formatDate(transaction.createdAt)}</Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>{isCommerce ? 'Caja' : 'Comercio'}</Label>
        <Label>
          {isCommerce
            ? !!transaction.cashier
              ? transaction.cashier.name
              : 'Admin'
            : transaction.commerce.name}
        </Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Factura</Label>
        <Label>{transaction.transactionNumber}</Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Transacción</Label>
        <Label>{shortTransactionNumber(transaction.id)}</Label>
      </Grid>
      {isCommerce && (
        <Grid item xs={12} sx={gridItemStyle}>
          <Label fontWeight={500}>Cédula del Cliente</Label>
          <Label>
            {transaction.buyer ? transaction.buyer.document : 'N/A'}
          </Label>
        </Grid>
      )}
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Moneda</Label>
        <Label>
          {transaction.currency === 'UYU' ? 'Pesos' : transaction.currency}
        </Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Importe</Label>
        <Label>{transaction.amount.toString()}</Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Estado</Label>
        <Label>
          {transaction.status === TransactionStatus.APPROVED
            ? 'Aprobada'
            : transaction.status === TransactionStatus.CANCELED
            ? 'Cancelada'
            : transaction.status === TransactionStatus.PENDING
            ? 'Pendiente'
            : [
                TransactionStatus['REJECTED-BY-CD'],
                TransactionStatus['REJECTED-BY-BUYER'],
                TransactionStatus['REJECTED-BY-COMMERCE']
              ].includes(transaction.status)
            ? 'Rechazada'
            : transaction.status === TransactionStatus.ANNULLED
            ? 'Anulada'
            : 'Verificada'}
        </Label>
      </Grid>
      {isCommerce && (
        <Grid item xs={12} sx={gridItemStyle}>
          <Label fontWeight={500}>Fecha de cierre</Label>
          <Label>
            {transaction.closedLot ? formatDate(transaction.closedLot) : '---'}
          </Label>
        </Grid>
      )}
      {isCommerce && (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '0.75rem',
            fontWeight: 600,
            height: 'auto'
          }}>
          <Label fontWeight={500}>Anular</Label>
          <Button
            sx={{
              padding: '0',
              margin: '0'
            }}
            onClick={() => handleCancelClick(transaction.id)}>
            {clickedTransactionId !== transaction.id ? (
              <CancelOutlinedIcon fontSize="small" />
            ) : (
              <CancelIcon fontSize="inherit" />
            )}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
