import { formatDate } from 'helpers/DateTransformer';
import React from 'react';

import { Grid } from '@mui/material';

import Label from 'design-system/Label';
import { Colors } from 'design-system/theme';

import {
  Transaction,
  TransactionStatus
} from 'redux/transaction/types/transaction.type';

interface PedingTransactionProp {
  pendingTransaction: Transaction;
}
export const PendingTransactionMobile: React.FC<PedingTransactionProp> = ({
  pendingTransaction
}) => {
  const gridItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
    fontWeight: 600
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '0.75rem',
            fontWeight: 600,
            marginTop: '0.5rem'
          }}>
          <Label fontWeight={500} color={Colors.white}>
            Comercio
          </Label>
          <Label color={Colors.white}>
            {pendingTransaction.commerce.businessName}
          </Label>
        </Grid>
        <Grid item xs={12} sx={gridItemStyle}>
          <Label fontWeight={500} color={Colors.white}>
            Fecha
          </Label>
          <Label color={Colors.white}>
            {formatDate(pendingTransaction.createdAt)}
          </Label>
        </Grid>
        <Grid item xs={12} sx={gridItemStyle}>
          <Label fontWeight={500} color={Colors.white}>
            Importe
          </Label>
          <Label color={Colors.white}>
            {pendingTransaction.amount.toString()}
          </Label>
        </Grid>
        <Grid item xs={12} sx={gridItemStyle}>
          <Label fontWeight={500} color={Colors.white}>
            Moneda
          </Label>
          <Label color={Colors.white}>{pendingTransaction.currency}</Label>
        </Grid>
        <Grid item xs={12} sx={gridItemStyle}>
          <Label fontWeight={500} color={Colors.white}>
            Cuotas
          </Label>
          <Label color={Colors.white}>
            {pendingTransaction.dues.toString()}
          </Label>
        </Grid>
        <Grid item xs={12} sx={gridItemStyle}>
          <Label fontWeight={500} color={Colors.white}>
            Factura
          </Label>
          <Label color={Colors.white}>
            {pendingTransaction.transactionNumber}
          </Label>
        </Grid>
        <Grid item xs={12} sx={gridItemStyle}>
          <Label fontWeight={500} color={Colors.white}>
            Transacción
          </Label>
          <Label color={Colors.white}>
            {pendingTransaction.id.slice(0, 8)}
          </Label>
        </Grid>
        <Grid item xs={12} sx={gridItemStyle}>
          <Label fontWeight={500} color={Colors.white}>
            Estado
          </Label>
          <Label color={Colors.white}>
            {pendingTransaction.status === TransactionStatus.APPROVED
              ? 'APROBADA'
              : pendingTransaction.status === TransactionStatus.CANCELED
              ? 'CANCELADA'
              : pendingTransaction.status === TransactionStatus.PENDING
              ? 'PENDIENTE'
              : [
                  TransactionStatus['REJECTED-BY-CD'],
                  TransactionStatus['REJECTED-BY-BUYER']
                ].includes(pendingTransaction.status)
              ? 'RECHAZADA'
              : 'VERIFICADA'}
          </Label>
        </Grid>
      </Grid>
    </>
  );
};

export default PendingTransactionMobile;
