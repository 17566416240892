import { Transaction } from 'redux/transaction/types/transaction.type';

import {
  GetTransactionsQueryParams,
  TransactionRequest
} from 'types/request/transactionCommerce.interface';
import { IApiResponse } from 'types/responses/apiResponse.interface';
import { CloseLot } from 'types/responses/closeLot.interface';
import { GetTransactionResponse } from 'types/responses/getTransactionResponse.type';

import { api } from './instance';

export const TransactionService = {
  get: async (
    params?: GetTransactionsQueryParams & { forPending?: boolean }
  ) => {
    const queryParams: string[] = [];

    if (params?.forPending) {
      queryParams.push('status=pending&itemsPerPage=0');
    }

    if (params?.page !== undefined) {
      queryParams.push(`page=${params.page}`);
    }

    if (params?.sortBy !== undefined) {
      queryParams.push(`sortBy=${params.sortBy}`);
    }

    if (params?.sortOrder !== undefined) {
      queryParams.push(`sortOrder=${params.sortOrder}`);
    }

    if (params?.term) {
      queryParams.push(`term=${params.term}`);
    }

    if (params?.status !== undefined) {
      queryParams.push(`status=${params.status}`);
    }

    if (params?.all !== undefined) {
      queryParams.push(`all=${params.all}`);
    }

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const resp = await api.get<IApiResponse<GetTransactionResponse>>(
      `/transaction${queryString}`
    );

    return resp.data.data;
  },
  getById: async (id: string) => {
    const resp = await api.get<IApiResponse<Transaction>>(`/transaction/${id}`);

    return resp.data.data;
  },
  forClose: async (params: { forAll: boolean }) => {
    const resp = await api.get<IApiResponse<Transaction[]>>(
      `/transaction/for-lot-closure/${params?.forAll ? '?for-all=true' : ''}`
    );

    return resp.data.data;
  },
  transactionRequest: async (transactionData: TransactionRequest) => {
    const res = await api.post<IApiResponse<Transaction>>(
      '/transaction',
      transactionData
    );
    return res.data.data;
  },
  approve: async (id: string) => {
    const res = await api.patch<IApiResponse<Transaction>>(
      `/transaction/${id}/approve`
    );
    return res.data.data;
  },
  reject: async (id: string) => {
    const res = await api.patch<IApiResponse<Transaction>>(
      `/transaction/${id}/reject`
    );
    return res.data.data;
  },
  closeTransactions: async (ids: string[]) => {
    const res = await api.patch<IApiResponse<CloseLot>>(
      `/transaction/close-lot?ids=${JSON.stringify(ids)}`
    );
    return res.data.data;
  }
};
