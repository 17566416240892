import { createAsyncThunk } from '@reduxjs/toolkit';

import { BuyerService } from '../../services/buyer.service';

export const buyerLogin = createAsyncThunk(
  'buyer/login',
  async (data: { email: string; password: string }) => {
    return BuyerService.login(data);
  }
);

export const me = createAsyncThunk('buyer/me', async () => {
  return BuyerService.me();
});
