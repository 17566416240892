import { NavLink } from 'react-router-dom';

import { ListItem, ListItemButton, Typography, useTheme } from '@mui/material';

import { SidebarOption } from 'design-system/Layouts/types/sidebarOptions.type';

interface ISidebarOptionsProps {
  onClick: () => void;
  options: SidebarOption[];
}

export const SidebarOptions = ({ options, onClick }: ISidebarOptionsProps) => {
  const theme = useTheme();
  return (
    <>
      {options.map(({ label, redirectTo, icon, onClick: optionClick }) => (
        <ListItem key={label} disablePadding sx={{ display: 'block' }}>
          <NavLink
            to={redirectTo}
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? 700 : 400,
                textDecoration: 'none'
              };
            }}
            end>
            {({ isActive }) => (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  px: 2.5
                }}
                onClick={() => {
                  if (optionClick) optionClick();
                  onClick();
                }}>
                <Typography
                  variant="body1"
                  px="1.25rem"
                  sx={{
                    color: theme.palette.getContrastText(
                      theme.palette.primary.main
                    ),
                    opacity: isActive ? 1 : 0.5,
                    fontWeight: 700
                  }}>
                  {label}
                </Typography>
              </ListItemButton>
            )}
          </NavLink>
        </ListItem>
      ))}
    </>
  );
};

export default SidebarOptions;
/* <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : 'auto',
                    justifyContent: 'center',
                    color: theme.palette.getContrastText(
                      theme.palette.primary.main
                    ),
                    opacity: isActive ? 1 : 0.5
                  }}>
                  {icon}
                </ListItemIcon> */
