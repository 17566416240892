import { FormikProps } from 'formik';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useState } from 'react';

import { Box, Grid, InputAdornment, TextField, useTheme } from '@mui/material';

import TitleLabel from 'design-system/Layouts/TitleLabels';
import { SelectorElement } from 'design-system/Selector';

import { commerceSelector } from 'redux/commerce/commerce.selector';
import Currency from 'redux/transaction/types/transaction.type';

import { plans } from '../types/commerce.type';

interface TransactionProps {
  formik: FormikProps<any>;
}
const TransactionForm: React.FC<TransactionProps> = ({ formik }) => {
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const [plans, setPlans] = useState<plans[] | undefined>(undefined);
  const [currencys, setCurrencys] = useState<string[] | undefined>(undefined);
  const [UYUdues, setUYUDues] = useState<string[] | undefined>(undefined);
  const [USDdues, setUSDDues] = useState<string[] | undefined>(undefined);
  const { commerce } = useAppSelector(commerceSelector);

  useEffect(() => {
    if (commerce) {
      try {
        setPlans(JSON.parse(commerce?.plans));
      } catch (error) {}
    }
  }, [commerce]);

  useEffect(() => {
    if (plans) {
      const exist = { UYU: false, USD: false };
      const tempCurrency = [];
      const tempUYU: string[] = [];
      const tempUSD: string[] = [];
      plans
        .filter((plan) => plan.producto === 'T')
        .sort((a, b) => (Number(a.cuotas) > Number(b.cuotas) ? 1 : -1))
        .forEach((plan) => {
          if (plan.moneda === 'P') {
            exist.UYU = true;
            tempUYU.push(plan.cuotas);
          } else if (plan.moneda === 'D') {
            exist.USD = true;
            tempUSD.push(plan.cuotas);
          }
        });
      exist.UYU && tempCurrency.push(Currency.UYU);
      exist.USD && tempCurrency.push(Currency.USD);
      setCurrencys(tempCurrency);
      setUYUDues(tempUYU);
      setUSDDues(tempUSD);
    }
  }, [plans]);

  return (
    <Box
      sx={{
        marginTop: '0',
        margin: 'auto',
        marginBottom: '1.5rem',
        border: `1px solid ${theme.palette.primary.main}`,
        padding: '1rem',
        paddingBottom: '1rem',
        borderRadius: '0.625rem',
        background: theme.palette.background.default
      }}>
      {currencys && (!!USDdues || !!UYUdues) && (
        <>
          <TitleLabel> Datos de la Transacción</TitleLabel>
          <Grid container justifyContent="space-between">
            <Grid item sx={{ my: 1 }} xs={12} md={12}>
              <TextField
                autoFocus
                label=""
                size={isLargeScreen ? undefined : 'small'}
                sx={{
                  width: '100%',
                  fontSize: isLargeScreen ? undefined : 10
                }}
                value={formik.values.customerID}
                onChange={(e) =>
                  formik.setFieldValue('customerID', e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      Cédula del cliente:
                    </InputAdornment>
                  )
                }}
                helperText={
                  formik.errors.customerID
                    ? 'Ingrese Cédula del Cliente sin el digito verificador y sin puntos'
                    : ''
                }
                error={!!formik.errors.customerID}
              />
            </Grid>
            <Grid item sx={{ my: 1 }} xs={12} md={12}>
              <TextField
                label=""
                size={isLargeScreen ? undefined : 'small'}
                sx={{
                  width: '100%',
                  fontSize: isLargeScreen ? undefined : 10
                }}
                value={formik.values.nameAndLastname}
                onChange={(e) =>
                  formik.setFieldValue('nameAndLastname', e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      Nombre y Apellido:
                    </InputAdornment>
                  )
                }}
                error={!!formik.errors.nameAndLastname}
              />
            </Grid>
            <Grid item sx={{ my: 1 }} xs={12} md={4.85} lg={4.75} xl={4.9}>
              <TextField
                label=""
                size={isLargeScreen ? undefined : 'small'}
                sx={{
                  width: '100%',
                  fontSize: isLargeScreen ? undefined : 10
                }}
                value={formik.values.transactionNumber}
                onChange={(e) =>
                  formik.setFieldValue('transactionNumber', e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      N° de Factura:
                    </InputAdornment>
                  )
                }}
                helperText={
                  formik.errors.transactionNumber
                    ? 'Ingrese un N° de Factura valido'
                    : ''
                }
                error={!!formik.errors.transactionNumber}
              />
            </Grid>
            <Grid item sx={{ my: 1 }} xs={12} md={3} lg={3.5}>
              <SelectorElement
                Options={currencys}
                Label="Moneda:"
                value={formik.values.currency}
                onValueChange={(value) =>
                  formik.setFieldValue('currency', value)
                }
                disableNone
              />
            </Grid>
            <Grid item sx={{ my: 1 }} xs={12} md={2.65} lg={3.05}>
              <SelectorElement
                Options={
                  (formik.values.currency === Currency.USD
                    ? USDdues
                    : formik.values.currency === Currency.UYU
                    ? UYUdues
                    : []) ?? []
                }
                Label="Cuotas:"
                onValueChange={(value) => formik.setFieldValue('dues', value)}
                value={formik.values.dues}
                disableNone
                disabled={!formik.values.currency}
                tooltip={
                  !formik.values.currency
                    ? 'Debe seleccionar una moneda primero'
                    : undefined
                }
              />
            </Grid>
            <Grid item sx={{ my: 1 }} xs={12} md={12}>
              <TextField
                label=""
                size={isLargeScreen ? undefined : 'small'}
                sx={{ width: '100%' }}
                value={formik.values.amount}
                onChange={(e) => formik.setFieldValue('amount', e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Importe:</InputAdornment>
                  )
                }}
                helperText={
                  formik.errors.amount ? 'Ingrese un importe valido' : ''
                }
                error={!!formik.errors.amount}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default TransactionForm;
