import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Box, Button, useTheme } from '@mui/material';

import Label from 'design-system/Label';

interface ModalRejectedProps {
  rejectLabel: string;
  handleClose: () => void;
  extraLabel?: string;
}

const ModalReject: React.FC<ModalRejectedProps> = ({
  rejectLabel,
  handleClose,
  extraLabel
}) => {
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box
      sx={{
        width: isLargeScreen ? undefined : '16rem',
        height: isLargeScreen ? undefined : '20rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem'
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="110"
        height="109"
        viewBox="0 0 110 109"
        fill="none">
        <circle
          cx="55"
          cy="54.1667"
          r="50"
          stroke="#C50202"
          strokeWidth="8.1"
        />
        <path
          d="M67.4999 41.6668L42.5 66.6667M42.4999 41.6667L67.4998 66.6666"
          stroke="#C50202"
          strokeWidth="8.1"
          strokeLinecap="round"
        />
      </svg>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: isLargeScreen ? '2%' : '12%',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Label
          fontSize={isLargeScreen ? 18 : 16}
          fontWeight={700}
          color={theme.palette.error.main}>
          Operación Rechazada
        </Label>
        <Label
          fontSize={isLargeScreen ? 12 : 10}
          color={theme.palette.error.main}>
          {rejectLabel}
        </Label>
      </Box>
      {extraLabel && (
        <Box display="flex" alignItems="center" gap={'0.5rem'}>
          <LocalPhoneIcon fontSize="small" color="primary" />
          <Label
            fontSize={isLargeScreen ? 16 : 12}
            color={theme.palette.primary.main}>
            {extraLabel}
          </Label>
        </Box>
      )}
      <Button
        fullWidth
        variant="contained"
        onClick={handleClose}
        sx={{ maxWidth: isLargeScreen ? '21rem' : '12rem' }}>
        Terminar
      </Button>
    </Box>
  );
};

export default ModalReject;
