import { useAppDispatch } from 'hooks/useAppDispatch';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { Box, Button, styled, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { setModalOpen } from 'redux/commerce/commerce.slice';
import {
  approveRequestAction,
  getPendingTransactions,
  getTransactions,
  rejectRequestAction
} from 'redux/transaction/transaction.action';
import { Transaction } from 'redux/transaction/types/transaction.type';

export const PendingTransaction = ({
  pendingTransaction
}: {
  pendingTransaction: Transaction;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const StyledTableCell = styled(TableCell)(
    () => `color: ${theme.palette.getContrastText(theme.palette.primary.main)};
           font-weight: 700;`
  );

  const updateTransactions = () => {
    dispatch(getTransactions(queryString.parse(location.search)));
    dispatch(getPendingTransactions());
  };

  const onApprove = async (id: string) => {
    const resp = await dispatch(approveRequestAction(id));
    if (resp.meta.requestStatus === 'fulfilled') {
      updateTransactions();
    }
  };
  const onReject = async (id: string) => {
    const resp = await dispatch(rejectRequestAction(id));
    if (resp.meta.requestStatus === 'fulfilled') {
      updateTransactions();
      setModalOpen(false);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="pending transaction table">
          <TableHead
            sx={{
              backgroundColor: theme.palette.primary.main
            }}>
            <TableRow>
              <StyledTableCell>Comercio</StyledTableCell>
              <StyledTableCell>Importe</StyledTableCell>
              <StyledTableCell>Moneda</StyledTableCell>
              <StyledTableCell>Cuotas</StyledTableCell>
              <StyledTableCell>Factura</StyledTableCell>
              <StyledTableCell>Transacción</StyledTableCell>
              <StyledTableCell>Estado</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{pendingTransaction.commerce.businessName}</TableCell>
              <TableCell>{pendingTransaction.amount}</TableCell>
              <TableCell>{pendingTransaction.currency}</TableCell>
              <TableCell>{pendingTransaction.dues}</TableCell>
              <TableCell>{pendingTransaction.transactionNumber}</TableCell>
              <TableCell>{pendingTransaction.id.slice(0, 8)}</TableCell>
              <TableCell>
                {pendingTransaction.status.toLocaleUpperCase()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" mt={2} gap={2}>
        <Button
          variant="contained"
          onClick={() => onApprove(pendingTransaction.id)}>
          Aprobar
        </Button>
        <Button
          variant="outlined"
          onClick={() => onReject(pendingTransaction.id)}
          sx={{ backgroundColor: theme.palette.background.paper }}>
          Rechazar
        </Button>
      </Box>
    </>
  );
};
export default PendingTransaction;
