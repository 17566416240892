import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetTransactionResponse } from 'types/responses/getTransactionResponse.type';

import {
  approveRequestAction,
  getForCloseTransactions,
  getPendingTransactions,
  getTransactionById,
  getTransactions,
  rejectRequestAction,
  transactionRequestAction
} from './transaction.action';
import { Transaction, TransactionStatus } from './types/transaction.type';
import {
  ITransactionState,
  TransactionModalStatus
} from './types/transactionState.interface';

const paginationInitialValue = { page: 1, totalItems: 0, totalPages: null };

const initialState: ITransactionState = {
  loading: false,
  transactions: [],
  pendingTransactions: [],
  pagination: paginationInitialValue,
  transactionModal: { isOpen: false },
  transactionsForClose: []
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTransactionModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.transactionModal.isOpen = action.payload;
      if (!action.payload) state.transactionModal.status = undefined;
    },
    setTransactionModalStatus: (
      state,
      action: PayloadAction<TransactionModalStatus>
    ) => {
      state.transactionModal.status = action.payload;
    },
    cleanTransactionModal: (state) => {
      state.transactionModal = { isOpen: false };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getTransactions.fulfilled,
        (state, action: PayloadAction<GetTransactionResponse>) => {
          state.transactions = action.payload.transactions;
          state.pagination = action.payload.pagination;
          state.errorMessage = undefined;
          state.loading = false;
        }
      )
      .addCase(getTransactions.rejected, (state) => {
        state.errorMessage = 'Ops something went wrong';
        state.transactions = [];
        state.pagination = paginationInitialValue;
        state.loading = false;
      })
      .addCase(getTransactions.pending, (state) => {
        state.loading = true;
      })

      .addCase(
        getPendingTransactions.fulfilled,
        (state, action: PayloadAction<GetTransactionResponse>) => {
          state.pendingTransactions = action.payload.transactions;
          state.errorMessage = undefined;
          state.loading = false;
        }
      )
      .addCase(getPendingTransactions.rejected, (state) => {
        state.errorMessage = 'Ops something went wrong';
        state.pendingTransactions = [];
        state.loading = false;
      })
      .addCase(getPendingTransactions.pending, (state) => {
        state.loading = true;
      })

      .addCase(
        transactionRequestAction.fulfilled,
        (state, action: PayloadAction<Transaction>) => {
          state.errorMessage = undefined;
          state.loading = false;
          state.transactionModal.transaction = action.payload;
          state.transactionModal.counter = 0;
          state.transactionModal.status = TransactionModalStatus.SENT;
          state.transactionModal.isOpen = true;
        }
      )
      .addCase(transactionRequestAction.rejected, (state) => {
        state.errorMessage = 'El número de factura ya existe';
        state.loading = false;
        state.transactionModal.transaction = undefined;
        state.transactionModal.status = TransactionModalStatus.FAILED;
      })
      .addCase(transactionRequestAction.pending, (state) => {
        state.loading = true;
        state.transactionModal.transaction = undefined;
        state.transactionModal.status = TransactionModalStatus.WAITING;
      })

      .addCase(
        approveRequestAction.fulfilled,
        (state, action: PayloadAction<Transaction>) => {
          switch (action.payload.status) {
            case TransactionStatus.APPROVED:
              state.transactionModal.status = TransactionModalStatus.APPROVED;
              return;
            default:
              state.transactionModal.status =
                TransactionModalStatus['REJECTED-BY-CD'];
              return;
          }
        }
      )
      .addCase(approveRequestAction.pending, (state) => {
        state.transactionModal.isOpen = true;
        state.transactionModal.status = TransactionModalStatus.WAITING;
      })
      .addCase(approveRequestAction.rejected, (state) => {
        state.transactionModal.status =
          TransactionModalStatus['REJECTED-BY-CD'];
      })

      .addCase(
        rejectRequestAction.fulfilled,
        (state, action: PayloadAction<Transaction>) => {
          state.loading = false;
          state.transactionModal.status = TransactionModalStatus.ANULLED;
          if (action.payload.status === TransactionStatus['REJECTED-BY-BUYER'])
            state.transactionModal.status =
              TransactionModalStatus['REJECTED-BY-BUYER'];
        }
      )
      .addCase(rejectRequestAction.pending, (state) => {
        state.loading = true;
        state.transactionModal.isOpen = true;
        state.transactionModal.status = TransactionModalStatus.ANULLING;
      })
      .addCase(rejectRequestAction.rejected, (state) => {
        state.loading = false;
        state.transactionModal.status = TransactionModalStatus.NOT_ANULLED;
      })

      .addCase(
        getForCloseTransactions.fulfilled,
        (state, action: PayloadAction<Transaction[]>) => {
          state.transactionsForClose = action.payload;
          state.pagination = {
            page: 1,
            totalItems: action.payload.length,
            totalPages: 1
          };
          state.loading = false;
        }
      )
      .addCase(getForCloseTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getForCloseTransactions.rejected, (state) => {
        state.loading = false;
      })

      .addCase(
        getTransactionById.fulfilled,
        (state, action: PayloadAction<Transaction>) => {
          state.transactionModal.transaction = action.payload;
          if (action.payload.status === TransactionStatus.APPROVED) {
            state.transactionModal.counter = undefined;
            state.transactionModal.transaction = undefined;
            state.transactionModal.status = TransactionModalStatus.APPROVED;
          } else if (
            [
              TransactionStatus['REJECTED-BY-BUYER'],
              TransactionStatus['REJECTED-BY-CD']
            ].includes(action.payload.status)
          ) {
            state.transactionModal.counter = undefined;
            state.transactionModal.transaction = undefined;
            state.transactionModal.status =
              action.payload.status === TransactionStatus['REJECTED-BY-BUYER']
                ? TransactionModalStatus['REJECTED-BY-BUYER']
                : TransactionModalStatus['REJECTED-BY-CD'];
          } else if (state.transactionModal.counter) {
            state.transactionModal.counter = state.transactionModal.counter + 1;
          }
        }
      );
  }
});

export default transactionSlice.reducer;
export const {
  setTransactionModalIsOpen,
  setTransactionModalStatus,
  cleanTransactionModal
} = transactionSlice.actions;
