import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Backdrop, Paper, useTheme } from '@mui/material';

import { Colors } from './theme';

interface ModalProps {
  open: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  modalWidth?: string;
  modalHeight?: string;
  modalRadius?: string;
}

const Modal: React.FC<ModalProps> = ({
  open,
  children,
  onClose,
  modalHeight,
  modalWidth,
  modalRadius = '2rem'
}) => {
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();

  const computedWidth = isLargeScreen ? modalWidth || 380 + 19 * 2 : '20rem';

  const computedHeight = isLargeScreen
    ? modalHeight || 260 + 19 * 2
    : undefined;
  const computedMinHeight = isLargeScreen
    ? modalHeight || 260 + 19 * 2
    : '15rem';
  return (
    <Backdrop
      sx={{
        color: theme.palette.primary.light,
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: Colors.fuzzy
      }}
      open={open}
      onClick={() => onClose && onClose()}>
      <Paper
        elevation={2}
        sx={{
          width: computedWidth,
          height: computedHeight,
          minHeight: computedMinHeight,
          borderRadius: modalRadius,
          backgroundColor: Colors.white,
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={(event) => event.stopPropagation()}>
        {children}
      </Paper>
    </Backdrop>
  );
};

export default Modal;
