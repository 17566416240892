import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect } from 'react';
import { newCashInitialValues, newCashSchema } from 'schemas/CashRegistrations';

import { Container, Grid, useTheme } from '@mui/material';

import ModalLoader from 'components/ModalLoader';

import TitleLabel from 'design-system/Layouts/TitleLabels';
import Modal from 'design-system/Modal';

import { createCashierAction } from 'redux/casher/casher.action';
import { CashierSelector } from 'redux/casher/casher.selector';
import {
  setCashierModalIsOpen,
  setSelectedCashier
} from 'redux/casher/casher.slice';
import { CashierModalStatus } from 'redux/casher/types/casherState.interface';
import { me } from 'redux/commerce/commerce.action';

import CashRegistrationForm from '../components/CashierRegistrationForm';
import { CommerceData } from '../components/CommerceData';
import { ModalCashDelete } from '../components/ModalCashierDelete';
import ModalCashEdition from '../components/ModalCashierEdition';
import { ModalCashierRestore } from '../components/ModalCashierRestore';

export const CashRegistrations: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { cashierModal } = useAppSelector(CashierSelector);
  const isLargeScreen = useIsLargeScreen();

  useEffect(() => {
    dispatch(me());
  }, [dispatch]);

  const handleSubmit = async (values: any, formikHelpers: any) => {
    const CashierLoad = {
      email: values.email,
      password: values.password,
      name: values.cashID,
      address: values.storeAddress,
      location: values.location,
      department: values.department,
      phoneNumber: values.phoneNumber
    };
    const resp = await dispatch(createCashierAction(CashierLoad));

    if (resp.meta.requestStatus === 'fulfilled') {
      formikHelpers.resetForm();
    }
  };
  const onModalClose = () => {
    dispatch(setCashierModalIsOpen(false));
    dispatch(setSelectedCashier(undefined));
  };

  return (
    <>
      <Modal
        open={cashierModal.isOpen}
        onClose={() => {
          onModalClose();
        }}
        modalWidth={
          cashierModal.status === CashierModalStatus.EDITING ? '64rem' : '20rem'
        }
        modalHeight={
          isLargeScreen
            ? cashierModal.status === CashierModalStatus.EDITING
              ? '32rem'
              : '10rem'
            : cashierModal.status === CashierModalStatus.EDITING
            ? undefined
            : '10rem'
        }
        modalRadius="0.625rem">
        {cashierModal.status === CashierModalStatus.EDITING && (
          <ModalCashEdition />
        )}
        {cashierModal.status === CashierModalStatus.DELETING && (
          <ModalCashDelete />
        )}
        {cashierModal.status === CashierModalStatus.RESTORING && (
          <ModalCashierRestore />
        )}
        {cashierModal.status === CashierModalStatus.WAITING && (
          <ModalLoader LoaderLabel={'Eliminando Caja'} />
        )}
        {cashierModal.status === CashierModalStatus.RESTORE_WAITING && (
          <ModalLoader LoaderLabel={'Restaurando Caja'} />
        )}
      </Modal>
      <Container
        maxWidth="lg"
        sx={{
          pb: '2rem',
          mt: isLargeScreen ? '7.5rem' : '6rem'
        }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={isLargeScreen ? 2 : 1}
          sx={{
            marginTop: '5rem'
          }}>
          {!isLargeScreen && (
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: '1rem'
              }}>
              <TitleLabel fontSize={isLargeScreen ? undefined : '0.875rem'}>
                Datos del comercio
              </TitleLabel>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            mx={isLargeScreen ? undefined : '1rem'}
            sx={{
              borderTop: `1px solid ${theme.palette.primary.main}`,
              borderBottom: `1px solid ${theme.palette.primary.main}`
            }}>
            <CommerceData />
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={newCashInitialValues}
              onSubmit={(values, formikHelpers) =>
                handleSubmit(values, formikHelpers)
              }
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={newCashSchema}>
              {(formik) => (
                <Form>
                  <CashRegistrationForm formik={formik} />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
